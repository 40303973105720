<script>
/**
 * Device alerts component
 */
import moment from "moment";
import { alertsMethods, authComputed } from "@/state/helpers";

export default {
  props: ["alerts", "busy"],
  components: {
  },
  data() {
    return {
      alertsList: [],
    };
  },
  computed: {
    ...authComputed,
    fieldsComputed() {
      return [
        { key: "date", sortable: false, label: this.$t("dataTable.dateTime.text") },
        { key: "state", sortable: false, label: this.$t("dataTable.status.text") },
        { key: "type", sortable: false, label: this.$t("dataTable.type.text") },
        { key: "value", sortable: false, label: this.$t("dataTable.value.text") },
      ];
    },
  },
  mounted() {},
  methods: {
    ...alertsMethods,
  },
  watch: {
    alerts(newVal) {
      let alertsListCustom = newVal.map((obj) => {
        return {
          ...obj,
          state: obj.state ? obj.state.name : null,
          date: moment(obj.alertedAt).format("DD-MM-YYYY HH:mm:ss"),
        };
      });
      this.alertsList = alertsListCustom;
    },
  },
};
</script>

<template>
  <div
    class="table-responsive mb-3 bg-white rounded-lg"
    style="min-height: 414px"
  >
    <h5 class="m-3">{{$t("titles.alerts.text")}}</h5>
    <b-table
      class="mb-0"
      hover
      show-empty
      centred
      :busy="busy"
      :empty-text="this.$t('dataTable.noAlerts.text')"
      :items="alertsList"
      :fields="fieldsComputed"
      stacked="sm"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <strong>{{ $t("dataTable.loading.text") }}</strong>
        </div>
      </template>

      <template v-slot:cell(state)="row">
        <div
          class="badge font-size-12 text-center"
          :class="{
            'badge-soft-warning': `${row.value}` === 'NEW',
            'badge-soft-info': `${row.value}` === 'OPENED',
            'badge-soft-secondary': `${row.value}` === 'SUSPENDED',
            'badge-soft-success': `${row.value}` === 'CLOSED',
          }"
        >
          {{ row.value }}
        </div>
      </template>
      <template v-slot:cell(type)="row">
        <div
          class="badge font-size-12"
          :class="{
            'badge-soft-primary': `${row.value}` === 'DOWN',
            'badge-soft-danger': `${row.value}` === 'UP',
          }"
        >
          {{ row.value }}
        </div>
      </template>
      <template v-slot:cell(value)="row">
        <div
          class="font-size-14 font-weight-bold"
          :class="{
            'text-danger': `${row.value}` > 8,
            'text-primary': `${row.value}` < 2,
          }"
        >
          {{ row.value }}
        </div>
      </template>
      <template v-slot:cell(date)="row">
        <div class="text-left">
          {{ row.value }}
        </div>
      </template>
    </b-table>
  </div>
</template>