var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticStyle:{"height":"460px"}},[(_vm.loader == true)?_c('div',{staticClass:"loaderDiv d-flex justify-content-center align-items-center"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"label":"Loading...","variant":"primary"}})],1):_vm._e(),(_vm.loader == false)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-12"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t("titles.deviceInfos.text")))])])]),_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-7"},[_c('p',{staticClass:"text-truncate",staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(this.deviceInfos.serial)+" ")])]),_c('div',{staticClass:"col-2"},[(typeof _vm.deviceInfos.extV == 'number')?_c('p',{staticStyle:{"display":"inline-block","font-size":"x-large"}},[_c('i',{staticClass:"mt-2 ri-plug-line",style:({
                  'color': _vm.deviceInfos.offline ? 'grey' : _vm.deviceInfos.extV == 0 ? 'red' : 'green',
                })}),_c('i',{staticClass:"ri-battery-2-charge-line",style:({
                  'color': _vm.deviceInfos.offline ? 'grey' : _vm.deviceInfos.batV < 10 ? 'red' : 'green',
                })})]):_vm._e(),(typeof _vm.deviceInfos.batV == 'number')?_c('p',{staticStyle:{"display":"inline-block","font-size":"large"},style:({
                'color': _vm.deviceInfos.offline ? 'grey' : _vm.deviceInfos.batV < 10 ? 'red' : 'green',
              })},[_vm._v(" "+_vm._s(_vm.deviceInfos.batV)+"% ")]):_vm._e()])]),_c('div',{staticClass:"row align-items-center mt-0"},[_c('div',{staticClass:"col-3"},[_c('p',{staticClass:"text-truncate",staticStyle:{"font-size":"16px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t("modals.name.text"))+" ")])]),_c('div',{staticClass:"col-9"},[_c('p',{staticClass:"text-truncate",staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(this.deviceInfos.name)+" ")])])]),_c('div',{staticClass:"row align-items-center mt-0"},[_c('div',{staticClass:"col-3"},[_c('p',{staticClass:"text-truncate",staticStyle:{"font-size":"16px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t("modals.region.text"))+" ")])]),_c('div',{staticClass:"col-9"},[_c('p',{staticClass:"text-truncate",staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(this.deviceInfos.region.name)+" ")])])]),_c('div',{staticClass:"row align-items-center mt-0"},[_c('div',{staticClass:"col-3"},[_c('p',{staticClass:"text-truncate",staticStyle:{"font-size":"16px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t("modals.province.text"))+" ")])]),_c('div',{staticClass:"col-9"},[_c('p',{staticClass:"text-truncate",staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(this.deviceInfos.province.name)+" ")])])]),_c('div',{staticClass:"row align-items-center mt-0"},[_c('div',{staticClass:"col-3"},[_c('p',{staticClass:"text-truncate",staticStyle:{"font-size":"16px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t("modals.stockType.text"))+" ")])]),_c('div',{staticClass:"col-9"},[_c('p',{staticClass:"text-truncate badge font-size-15",class:{
                'badge-danger':
                  `${this.deviceInfos.stockType}` === '24H' ||
                  `${this.deviceInfos.stockType}` === '24 H' ||
                  `${this.deviceInfos.stockType}` === '24 H ' ||
                  `${this.deviceInfos.stockType}` === '24H ',
                'badge-warning':
                  `${this.deviceInfos.stockType}` === 'DEMI JOURNEE' ||
                  `${this.deviceInfos.stockType}` === 'jusqu\'à 20H' ||
                  `${this.deviceInfos.stockType}` === 'Jusqu\'à 20h',
                'badge-success':
                  `${this.deviceInfos.stockType}` === 'VIDE',
                'badge-secondary':
                  `${this.deviceInfos.stockType}` === '-' ||
                  `${this.deviceInfos.stockType}` === 'NON COMMUNIQUE',
              }},[_vm._v(" "+_vm._s(this.deviceInfos.stockType)+" ")])])]),(this.deviceInfos.phones)?_c('div',{staticClass:"row align-items-center mt-0"},[_c('div',{staticClass:"col-3"},[_c('p',{staticClass:"text-truncate",staticStyle:{"font-size":"16px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t("modals.contacts.text"))+" ")])]),_c('div',{staticClass:"col-9"},[_c('p',{staticClass:"text-truncate",staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(this.deviceInfos.phones ? this.deviceInfos.phones.join(" | ") : "")+" ")])])]):_vm._e(),(this.deviceInfos.vaccinTypes)?_c('div',{staticClass:"row align-items-center mt-0"},[_c('div',{staticClass:"col-3"},[_c('p',{staticClass:"text-truncate",staticStyle:{"font-size":"16px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t("modals.vaccinTypes.text"))+" ")])]),_c('div',{staticClass:"col-9"},[_c('p',{staticClass:"text-truncate",staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(this.deviceInfos.vaccinTypes ? this.vaccinTypes.join(", ") : "")+" ")])])]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('p',{staticClass:"text-truncate",staticStyle:{"font-size":"16px","font-weight":"bold"}},[_vm._v(" Serial ")])])
}]

export { render, staticRenderFns }