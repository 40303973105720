<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import DeviceInfos from "./device-infos";
import DevicePosition from "./position";
import DeviceTemp from "./device-temp";
import DeviceAlerts from "./device-alerts";
import DeviceLogs from "./device-logs";

import EditDevice from "../devices/update-device";

import {
  layoutComputed,
  authComputed,
  devicesComputed,
  alertsComputed,
  logsComputed,
  devicesMethods,
  alertsMethods,
  logsMethods,
  regionsMethods,
  provincesMethods,
  regionsComputed,
  provincesComputed,
  vaccintypesMethods,
  vaccintypesComputed,
  marquesMethods,
  marquesComputed,
} from "@/state/helpers";
/**
 * device component
 */
export default {
  page: {
    title: "Device",
    meta: [{ name: "Device" }],
  },
  components: {
    Layout,
    PageHeader,
    DeviceInfos,
    DevicePosition,
    DeviceTemp,
    DeviceAlerts,
    DeviceLogs,
    EditDevice,
  },
  data() {
    return {
      title: "titles.device.text",
      deviceData: {},
      deviceEvents: [],

      alertsData: [],

      logsData: [],

      eventsData: [],

      regionsData: [],
      provincesData: [],
      vaccintypesData: [],
      marquesData: [],

      currentPage: 1,
      perPage: 6,

      currentPageLogs: 1,
      perPageLogs: 6,

      deviceLoader: false,
      eventsLoader: false,
      busy: false,
      busyLogs: false,

      dates: [],
      fromTime: "00:00",
      toTime: "00:00",
      fromContext: null,
      toContext: null,
      minDate: "2000-01-01",
      menu: false,
      sendTime: "",
      errTime: null,
      searchStatus: false,

      deviceState: 1,
      deviceStates: [
        { value: 1, text: "Actif" },
        { value: 2, text: "Non conforme" },
        { value: 3, text: "Defectueux" }
      ]
    };
  },
  created() {
    let serial = this.$route.params.serial;
    this.retrieveDevice(serial).then(async (device) => {
      let params = {
        serial: device.serial,
        limit: 100,
        sortBy: "createdAt:desc",
      };
      await this.retrieveEvents(params);
      await this.retrieveAlerts({ ref: device.ref });
      await this.retrieveLogs({ ref: device.ref });
      if (this.getRegions.results) this.regionsData = this.getRegions.results;
      else await this.retrieveRegions();
      if (this.getVaccintypes.results) this.vaccintypesData = this.getVaccintypes.results;
      else await this.retrieveVaccinTypes();
      if (this.getMarques.results) this.marquesData = this.getMarques.results;
      else await this.retrieveMarques();
      if (this.getProvinces.results)
        this.provincesData = this.getProvinces.results;
      else await this.retrieveProvinces();
    });
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...devicesComputed,
    ...alertsComputed,
    ...logsComputed,
    ...regionsComputed,
    ...provincesComputed,
    ...vaccintypesComputed,
    ...marquesComputed,

    dateRangeText() {
      if (this.dates.length > 0) {
        return this.dates.join(" ~ ");
      } else {
        return this.$t("modals.choosePeriod.text");
      }
    },

    items() {
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.device.text"),
          active: true,
        },
      ];
    },
  },
  methods: {
    ...devicesMethods,
    ...alertsMethods,
    ...logsMethods,
    ...regionsMethods,
    ...provincesMethods,
    ...vaccintypesMethods,
    ...marquesMethods,

    retrieveDevice(serial) {
      this.deviceLoader = true;
      return this.getDeviceInfosBySerial(serial)
        .then((device) => {
          this.deviceLoader = false;
          return device;
        })
        .catch((error) => {
          return error;
        });
    },
    retrieveAlerts({ ref, from, to }) {
      let params = {
        ref: ref,
        page: this.currentPage,
        limit: this.perPage,
        sortBy: "createdAt:desc",
        all: true
      };
      if (from && to) {
        params = {
          ...params,
          from: from,
          to: to,
        };
      }
      this.busy = true;
      return this.getAlertsBy(params)
        .then((alerts) => {
          this.busy = false;
          return alerts;
        })
        .catch((error) => {
          return error;
        });
    },
    retrieveLogs({ ref, from, to }) {
      let params = {
        ref: ref,
        page: this.currentPageLogs,
        limit: this.perPageLogs,
        sortBy: "createdAt:desc",
      };
      if (from && to) {
        params = {
          ...params,
          from: from,
          to: to,
        };
      }
      this.busyLogs = true;
      return this.getLogsBy(params)
        .then((logs) => {
          this.busyLogs = false;
          return logs;
        })
        .catch((error) => {
          return error;
        });
    },
    retrieveEvents({ serial, from, to }) {
      let params = {
        serial: serial,
        sortBy: "createdAt:desc",
      };
      if (from && to) {
        params = {
          ...params,
          limit: 100000,
          from: from,
          to: to,
        };
      }
      this.eventsLoader = true;
      return this.getEventsBy(params)
        .then((events) => {
          this.eventsLoader = false;
          return events;
        })
        .catch((error) => {
          return error;
        });
    },
    retrieveRegions() {
      return this.getRegionsBy({ limit: 100 }).then((regions) => {
          return regions;
        });
    },
    retrieveProvinces() {
      return this.getProvincesBy({ limit: 100 }).then((provinces) => {
          return provinces;
        });
    },
    retrieveVaccinTypes() {
      return this.getVaccintypesBy({ limit: 100 }).then((vaccintypes) => {
          return vaccintypes;
        });
    },
    retrieveMarques() {
      return this.getMarquesBy({ limit: 100 }).then((marques) => {
        return marques;
      });
    },
    handlePageChange(value) {
      this.currentPage = value;
      let params = {
        ref: this.deviceData.ref,
        from: this.dates.length == 2 ? `${this.dates[0]} ${this.fromTime}` : "",
        to: this.dates.length == 2 ? `${this.dates[1]} ${this.toTime}` : "",
      };
      this.retrieveAlerts(params);
    },
    handlePageLogsChange(value) {
      this.currentPageLogs = value;
      let params = {
        ref: this.deviceData.ref,
        from: this.dates.length == 2 ? `${this.dates[0]} ${this.fromTime}` : "",
        to: this.dates.length == 2 ? `${this.dates[1]} ${this.toTime}` : "",
      };
      this.retrieveLogs(params);
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    updateDevicePos(position) {
      let deviceUpdate = {
        deviceId: this.deviceData.id,
        name: this.deviceData.name,
        serial: this.deviceData.serial,
        type: this.deviceData.type,
        stockType: this.deviceData.stockType,
        ...position,
      };
      this.updateDevice(deviceUpdate)
        .then((device) => {
          this.makeToast(
            "Update device",
            device.name + " has been updated",
            "success"
          );
        })
        .catch((error) => {
          console.log("error", error);
          this.makeToast("Update device", error, "danger");
        });
    },
    updateDeviceState(state) {
      let deviceUpdate = {
        deviceId: this.deviceData.id,
        name: this.deviceData.name,
        serial: this.deviceData.serial,
        type: this.deviceData.type,
        stockType: this.deviceData.stockType,
        state: state,
      };
      this.updateDevice(deviceUpdate)
        .then((device) => {
          this.makeToast(
            "Update device",
            device.name + " has been updated",
            "success"
          );
        })
        .catch((error) => {
          console.log("error", error);
          this.makeToast("Update device", error, "danger");
        });
    },
    searchEvents() {
      if (this.dates.length == 2) {
        let from = `${this.dates[0]} ${this.fromTime}`;
        let to = `${this.dates[1]} ${this.toTime}`;
        let params = {
          serial: this.deviceData.serial,
          from: from,
          to: to,
        };
        if (this.dates[0] == this.dates[1]) {
          let fromHoure = this.fromContext.hours;
          let fromMinute = this.fromContext.minutes;
          let toHoure = this.toContext.hours;
          let toMinute = this.toContext.minutes;
          if (toHoure >= fromHoure) {
            if (
              toHoure > fromHoure ||
              (toHoure == fromHoure && toMinute >= fromMinute)
            ) {
              this.$refs.menu.save(this.dates);
              this.retrieveEvents(params).then(async () => {
                this.currentPage = 1;
                this.currentPageLogs = 1;
                await this.retrieveAlerts(params);
                await this.retrieveLogs(params);
              });
              this.searchStatus = true;
            } else {
              this.makeToast(
                "Date",
                this.$t("msgError.periodDate.text"),
                "danger"
              );
            }
          } else {
            this.makeToast(
              "Date",
              this.$t("msgError.periodDate.text"),
              "danger"
            );
          }
          /*  */
        } else {
          this.$refs.menu.save(this.dates);
          this.retrieveEvents(params).then(async () => {
            await this.retrieveAlerts({from, to, ref: this.deviceData.ref});
            await this.retrieveLogs({from, to, ref: this.deviceData.ref});
          });
          this.searchStatus = true;
        }
      }
    },
    onFromContext(ctx) {
      this.fromContext = ctx;
    },
    onToContext(ctx) {
      this.toContext = ctx;
    },
    clear() {
      this.$refs.menu.save(this.dates);
      this.retrieveEvents({ serial: this.deviceData.serial }).then(async () => {
        this.dates = [];
        this.fromTime = "00:00";
        this.toTime = "00:00";
        this.minDate = "2000-01-01";
        this.menu = false;
        this.sendTime = "";
        this.errTime = null;
        this.searchStatus = false;
        this.currentPage = 1;
        this.currentPageLogs = 1;
        await this.retrieveAlerts({
          ref: this.deviceData.ref,
        });
        await this.retrieveLogs({
          ref: this.deviceData.ref,
        });
      });
    },
    showModalEdit() {
      this.$bvModal.show("edit-device-modal");
    },
    hideModalEdit() {
      this.$bvModal.hide("edit-device-modal");
    },
    passDevice() {
      this.showModalEdit();
    },
    edit(device) {
      this.updateDevice(device)
        .then((device) => {
          this.makeToast(
            "Update device",
            device.name + " has been updated",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Update device", error, "danger");
        });
    },
  },
  watch: {
    getDevice(newVal) {
      this.deviceData = newVal;
      //this.deviceState = newVal.state || 1
    },
    getEvents(newVal) {
      this.eventsData = newVal.results;
    },
    getAlerts(newVal) {
      this.alertsData = newVal.results;
    },
    getLogs(newVal) {
      this.logsData = newVal.results;
    },
    getRegions(newVal) {
      this.regionsData = newVal.results;
    },
    getProvinces(newVal) {
      this.provincesData = newVal.results;
    },
    getVaccintypes(newVal) {
      this.vaccintypesData = newVal.results;
    },
    getMarques(newVal) {
      this.marquesData = newVal.results;
    },
    dates(newVal) {
      if (newVal.length == 1) {
        this.minDate = newVal[0];
      } else {
        this.minDate = "2000-01-01";
      }
    },
    deviceState(state){
      this.updateDeviceState(state)
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div v-if="loggedIn.user.role != 'USER'" class="row">
      <div class="col-11 text-right">
        <b-form-select
            id="state"
            v-model="deviceState"
            :options="deviceStates"
            class="mr-2 w-25"
          >
        </b-form-select>
      </div>
      <div class="col-1 text-right">
        <b-button type="button" class="mr-2" variant="light" @click="passDevice"
          ><i class="ri-pencil-fill"></i>
        </b-button>
        <EditDevice
          v-bind:device="deviceData"
          @editDevice="edit"
          :regions="regionsData"
          :provinces="provincesData"
          :marques="marquesData"
          :vaccintypes="vaccintypesData"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-8">
                <h4 class="card-title mb-4">
                  {{ $t("charts.temperature.text") }}
                </h4>
              </div>
              <div class="col-4 text-right" data-app>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dates"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates"
                    no-title
                    range
                    scrollable
                    color="#e52342"
                    :min="minDate"
                  >
                    <div class="row">
                      <div class="col-4 d-flex">
                        <!--  {{ $t("modals.fromTime.text") }} -->
                        <b-time
                          hide-header
                          locale="fr"
                          v-model="fromTime"
                          style="font-size: 4px"
                          @context="onFromContext"
                        ></b-time>
                      </div>
                      <div class="col-4 d-flex">
                        <!-- {{ $t("modals.toTime.text") }} -->
                        <b-time
                          hide-header
                          locale="fr"
                          v-model="toTime"
                          style="font-size: 4px"
                          @context="onToContext"
                        ></b-time>
                      </div>
                      <div class="col-4 text-center">
                        <b-button
                          class="mb-1"
                          variant="success"
                          @click="searchEvents(true)"
                        >
                          {{ $t("buttons.valid.text") }}
                        </b-button>
                        <b-button
                          v-if="searchStatus"
                          variant="secondary"
                          @click="clear"
                        >
                          {{ $t("buttons.clear.text") }}
                        </b-button>
                      </div>
                    </div>
                  </v-date-picker>
                </v-menu>
              </div>
            </div>
            <DeviceTemp
              v-bind:events="eventsData"
              :device="deviceData"
              :rangeTime="sendTime"
              :loader="eventsLoader"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-12">
        <div class="card">
          <div class="row">
            <div class="col-12 col-md-6">
              <DeviceInfos v-bind:device="deviceData" :loader="deviceLoader" />
            </div>
            <div class="col-12 col-md-6">
              <DevicePosition
                v-bind:device="deviceData"
                :events="eventsData"
                @updatePosition="updateDevicePos"
                :loader="eventsLoader"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-4">
        <DeviceAlerts v-bind:alerts="alertsData" :busy="busy" />
        <div class="row">
          <div class="col-12">
            <div
              class="dataTables_paginate paging_simple_numbers float-right mb-3"
            >
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="getAlerts.totalResults"
                  :per-page="perPage"
                  @change="handlePageChange"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-8">
        <DeviceLogs v-bind:device="deviceData" :logs="logsData" :busy="busyLogs" />
        <div class="row">
          <div class="col-12">
            <div
              class="dataTables_paginate paging_simple_numbers float-right mb-3"
            >
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPageLogs"
                  :total-rows="getLogs.totalResults"
                  :per-page="perPageLogs"
                  @change="handlePageLogsChange"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>