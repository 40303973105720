<script>
/**
 * device infos Component
 */
export default {
  props: ["device", "loader"],
  data() {
    return {
      deviceInfos: {},
      vaccinTypes: [],
    };
  },
  created() {},
  watch: {
    device(newVal) {
      this.deviceInfos = newVal;
      this.deviceInfos.batV = newVal.batV ?
        newVal.batV > 4.2 ? 100 :
        newVal.batV < 3.1 ? 0 :
        Math.round(((newVal.batV - 3.1) * 100) / ( 4.2 - 3.1 )) : null
      newVal.vaccinTypes.forEach(obj => {
        this.vaccinTypes.push(obj.name);
      })
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div style="height: 460px">
        <div
          v-if="loader == true"
          class="loaderDiv d-flex justify-content-center align-items-center"
        >
          <b-spinner
            label="Loading..."
            variant="primary"
            style="width: 3rem; height: 3rem"
          ></b-spinner>
        </div>
        <div v-if="loader == false" class="card-body">
          <div class="row mb-4">
            <div class="col-12">
              <h4 class="card-title">{{ $t("titles.deviceInfos.text") }}</h4>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-3">
              <p
                class="text-truncate"
                style="font-size: 16px; font-weight: bold"
              >
                Serial
              </p>
            </div>
            <div class="col-7">
              <p class="text-truncate" style="font-size: 15px">
                {{ this.deviceInfos.serial }}
              </p>
            </div>
            <div class="col-2">
              <p
                v-if="typeof deviceInfos.extV == 'number'"
                style="display: inline-block; font-size: x-large;"
              >
                <i 
                  class="mt-2 ri-plug-line"
                  :style="{
                    'color': deviceInfos.offline ? 'grey' : deviceInfos.extV == 0 ? 'red' : 'green',
                  }">
                </i>
                <i 
                  class="ri-battery-2-charge-line"
                  :style="{
                    'color': deviceInfos.offline ? 'grey' : deviceInfos.batV < 10 ? 'red' : 'green',
                  }"
                  >
                </i>
              </p>
              <p
                v-if="typeof deviceInfos.batV == 'number'"
                style="display: inline-block; font-size: large;"
                :style="{
                  'color': deviceInfos.offline ? 'grey' : deviceInfos.batV < 10 ? 'red' : 'green',
                }"
              >
                {{deviceInfos.batV}}% 
              </p>
            </div>
          </div>
          <div class="row align-items-center mt-0">
            <div class="col-3">
              <p
                class="text-truncate"
                style="font-size: 16px; font-weight: bold"
              >
                {{ $t("modals.name.text") }}
              </p>
            </div>
            <div class="col-9">
              <p class="text-truncate" style="font-size: 15px">
                {{ this.deviceInfos.name }}
              </p>
            </div>
          </div>
          <div class="row align-items-center mt-0">
            <div class="col-3">
              <p
                class="text-truncate"
                style="font-size: 16px; font-weight: bold"
              >
                {{ $t("modals.region.text") }}
              </p>
            </div>
            <div class="col-9">
              <p class="text-truncate" style="font-size: 15px">
                {{ this.deviceInfos.region.name }}
              </p>
            </div>
          </div>
          <div class="row align-items-center mt-0">
            <div class="col-3">
              <p
                class="text-truncate"
                style="font-size: 16px; font-weight: bold"
              >
                {{ $t("modals.province.text") }}
              </p>
            </div>
            <div class="col-9">
              <p class="text-truncate" style="font-size: 15px">
                {{ this.deviceInfos.province.name }}
              </p>
            </div>
          </div>
          <div class="row align-items-center mt-0">
            <div class="col-3">
              <p
                class="text-truncate"
                style="font-size: 16px; font-weight: bold"
              >
                {{ $t("modals.stockType.text") }}
              </p>
            </div>
            <div class="col-9">
              <p
                class="text-truncate badge font-size-15"
                :class="{
                  'badge-danger':
                    `${this.deviceInfos.stockType}` === '24H' ||
                    `${this.deviceInfos.stockType}` === '24 H' ||
                    `${this.deviceInfos.stockType}` === '24 H ' ||
                    `${this.deviceInfos.stockType}` === '24H ',
                  'badge-warning':
                    `${this.deviceInfos.stockType}` === 'DEMI JOURNEE' ||
                    `${this.deviceInfos.stockType}` === 'jusqu\'à 20H' ||
                    `${this.deviceInfos.stockType}` === 'Jusqu\'à 20h',
                  'badge-success':
                    `${this.deviceInfos.stockType}` === 'VIDE',
                  'badge-secondary':
                    `${this.deviceInfos.stockType}` === '-' ||
                    `${this.deviceInfos.stockType}` === 'NON COMMUNIQUE',
                }"
              >
                {{ this.deviceInfos.stockType }}
              </p>
            </div>
          </div>
          <div v-if="this.deviceInfos.phones" class="row align-items-center mt-0">
            <div class="col-3">
              <p
                class="text-truncate"
                style="font-size: 16px; font-weight: bold"
              >
                {{ $t("modals.contacts.text") }}
              </p>
            </div>
            <div class="col-9">
              <p class="text-truncate" style="font-size: 15px">
                {{ this.deviceInfos.phones ? this.deviceInfos.phones.join(" | ") : "" }}
              </p>
            </div>
          </div>
          <div v-if="this.deviceInfos.vaccinTypes" class="row align-items-center mt-0">
            <div class="col-3">
              <p
                class="text-truncate"
                style="font-size: 16px; font-weight: bold"
              >
                {{ $t("modals.vaccinTypes.text") }}
              </p>
            </div>
            <div class="col-9">
              <p class="text-truncate" style="font-size: 15px">
                {{ this.deviceInfos.vaccinTypes ? this.vaccinTypes.join(", ") : "" }}
              </p>
            </div>
          </div>
          <!-- <div class="row align-items-center mt-0">
            <div class="col-3">
              <p
                class="text-truncate"
                style="font-size: 16px; font-weight: bold"
              >
                Vaccin
              </p>
            </div>
            <div class="col-9">
              <p
                v-if="deviceInfos.vaccinType"
                class="badge font-size-12"
                :class="{
                  'badge-danger':
                    `${deviceInfos.vaccinType.toUpperCase()}` === 'COVID19',
                  'badge-primary':
                    `${deviceInfos.vaccinType.toUpperCase().includes('INSULINE')}` === 'true',
                  'badge-success': `${deviceInfos.vaccinType.toUpperCase().includes('PNI')}` === 'true',
                  'badge-warning':
                    `${deviceInfos.vaccinType.toUpperCase().includes('SERUM')}` === 'true'
                }"
              >
                {{ deviceInfos.vaccinType }}
              </p>
            </div>
          </div> -->


        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
<style scoped>
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
}
</style>