var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive mb-3 bg-white rounded-lg",staticStyle:{"min-height":"414px"}},[_c('h5',{staticClass:"m-3"},[_vm._v(_vm._s(_vm.$t("titles.alerts.text")))]),_c('b-table',{staticClass:"mb-0",attrs:{"hover":"","show-empty":"","centred":"","busy":_vm.busy,"empty-text":this.$t('dataTable.noAlerts.text'),"items":_vm.alertsList,"fields":_vm.fieldsComputed,"stacked":"sm"},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t("dataTable.loading.text")))])])]},proxy:true},{key:"cell(state)",fn:function(row){return [_c('div',{staticClass:"badge font-size-12 text-center",class:{
          'badge-soft-warning': `${row.value}` === 'NEW',
          'badge-soft-info': `${row.value}` === 'OPENED',
          'badge-soft-secondary': `${row.value}` === 'SUSPENDED',
          'badge-soft-success': `${row.value}` === 'CLOSED',
        }},[_vm._v(" "+_vm._s(row.value)+" ")])]}},{key:"cell(type)",fn:function(row){return [_c('div',{staticClass:"badge font-size-12",class:{
          'badge-soft-primary': `${row.value}` === 'DOWN',
          'badge-soft-danger': `${row.value}` === 'UP',
        }},[_vm._v(" "+_vm._s(row.value)+" ")])]}},{key:"cell(value)",fn:function(row){return [_c('div',{staticClass:"font-size-14 font-weight-bold",class:{
          'text-danger': `${row.value}` > 8,
          'text-primary': `${row.value}` < 2,
        }},[_vm._v(" "+_vm._s(row.value)+" ")])]}},{key:"cell(date)",fn:function(row){return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(row.value)+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }