<script>
import L from "leaflet";
import {
  LMap,
  LMarker,
  LPolyline,
  LTileLayer,
  LTooltip,
  LIcon,
} from "vue2-leaflet";
/**
 * Position component
 */

export default {
  props: ["device", "events", "loader"],
  created() {
    //this.chargePosition(this.room);
  },
  components: {
    LMap,
    LPolyline,
    LMarker,
    LIcon,
    LTooltip,
    LTileLayer,
  },
  data() {
    return {
      deviceData: {},
      province: null,
      eventsData: [],
      positionsData: [],
      marker: {
        position: undefined,
      },
      markerStart: {
        position: undefined,
      },
      //url: "https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=jab0Ntex6UOajMfdGw1Z9P4sLbfqsfBGQ6UQ6_4NKps",
      url: "http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}",
      //url: "http://tile.openstreetmap.ma/osm/{z}/{x}/{y}.png",
      staticAnchor: [16, 10],
      icon: L.icon({
        iconUrl: require("@/assets/images/iconDevice.svg"),
        iconSize: [41, 41],
        iconAnchor: [16, 37],
      }),
      iconAnchor: [20, 40],
      iconSize: [40, 40],
      tooltipAnchor: [0, -30],

      iconAnchorStart: [8, 16],
      iconSizeStart: [20, 20],

      imageStart: require("@/assets/images/pin_green.svg"),

      imageDeviceGreen: require("@/assets/images/pin_green.svg"),
      imageDeviceRed: require("@/assets/images/pin_red.svg"),
      imageDeviceGray: require("@/assets/images/pin_gray.svg"),
      imageDeviceOrange: require("@/assets/images/pin_orange.svg"),

      updateStatus: false,
      newPosition: {
        lat: null,
        lng: null,
      },
    };
  },
  methods: {
    openUpdate() {
      this.updateStatus = true;
    },
    validUpdate() {
      if (this.newPosition.lat && this.newPosition.lng) {
        this.$emit("updatePosition", this.newPosition);
        this.newPosition = {
          lat: null,
          lng: null,
        };
      }
      this.closeTooltip();
      this.updateStatus = false;
    },
    changeLatLng(position) {
      this.newPosition = {
        lat: position.latlng.lat,
        lng: position.latlng.lng,
      };
    },
    openTooltip() {
      this.$refs.tooltip.$emit("open");
    },
    closeTooltip() {
      this.$refs.tooltip.$emit("close");
    },
  },
  watch: {
    device(newVal) {
      this.deviceData = newVal;
      this.province = newVal.province.name;
      if (newVal.province.name != "Camion") {
        this.marker = {
          ...newVal,
          position: { lat: newVal.lat, lng: newVal.lng },
        };
      }
    },
    events(newVal) {
      if (this.device.province.name == "Camion") {
        let length = newVal.length;
        let positions = newVal.filter((obj) => obj.lat != 0 && obj.lng != 0);
        this.marker = {
          ...this.device,
          position: { lat: positions[0].lat, lng: positions[0].lng },
        };
        this.markerStart = {
          position: {
            lat: positions[length - 1].lat,
            lng: positions[length - 1].lng,
          },
        };
        this.positionsData = [];
        positions.reverse();
        positions.map((obj) => {
          this.positionsData.push([obj.lat, obj.lng]);
        });
      }
    },
  },
};
</script>

<template>
  <div style="height: 450px">
    <div
      v-if="loader == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div v-if="loader == false" class="card-body" style="position: relative">
      <div
        class="mt-2 d-flex"
        style="
          position: absolute;
          z-index: 999;
          width: 80%;
          left: 9%;
          bottom: 2%;
        "
      >
        <p
          v-if="updateStatus == false && province != 'Camion'"
          class="badge badge-danger font-size-13"
          style="cursor: pointer"
          @click="openUpdate()"
        >
          {{ $t("buttons.changePosition.text") }}
        </p>
        <p
          v-if="updateStatus == true"
          id="validateBtn"
          class="badge badge-success font-size-13"
          style="cursor: pointer"
          @click="openTooltip"
        >
          {{ $t("buttons.validPosition.text") }}
        </p>
        <b-tooltip ref="tooltip" target="validateBtn" variant="light">
          {{ $t("tooltips.sure.text") }}<br />
          <b-button variant="success" size="sm" @click="validUpdate(false)"
            >Yes</b-button
          >
          <b-button variant="danger" size="sm" @click="closeTooltip"
            >No</b-button
          >
        </b-tooltip>
        <p v-if="updateStatus == true" style="color: gray; font-weight: bold">
          &nbsp;{{ $t("buttons.dragMarker.text") }}
        </p>
      </div>
      <l-map
        style="height: 420px; width: 100%"
        :zoom="7"
        :min-zoom="4"
        :center="marker.position"
      >
        <l-tile-layer :url="url" :subdomains="['mt0', 'mt1', 'mt2', 'mt3']" />
        <l-marker
          :zIndexOffset="100"
          v-if="marker.position"
          :draggable="updateStatus"
          :lat-lng="marker.position"
          :icon="icon"
          @drag="changeLatLng"
        >
          <l-icon
            :icon-anchor="iconAnchor"
            :icon-size="iconSize"
            :tooltipAnchor="tooltipAnchor"
          >
            <img
              v-if="marker.stockType == 'VIDE' && marker.offline == false"
              :src="imageDeviceGreen"
            />
            <img
              v-if="marker.stockType == '24H' || marker.stockType == '24 H' && marker.offline == false"
              :src="imageDeviceRed"
            />
            <img
              v-if="
                marker.stockType == 'DEMI JOURNEE' ||
                marker.stockType == 'Jusqu\'à 20h' ||
                marker.stockType == 'jusqu\'à 16H' && 
                marker.offline == false
              "
              :src="imageDeviceOrange"
            />
            <img
              v-if="
                marker.stockType == '-' || marker.stockType == 'NON COMMUNIQUE'  || marker.offline == true
              "
              :src="imageDeviceGray"
            />
          </l-icon>
          <l-tooltip
            class="rounded"
            :options="{
              interactive: 'true',
              direction: 'top',
            }"
          >
            <span style="font-weight: 550"
              >{{ $t("tooltips.name.text") }} :
            </span>
            {{ marker.name }}<br />
            <span style="font-weight: 550"
              >{{ $t("tooltips.stockType.text") }} :
            </span>
            <span
              class="badge font-size-12"
              :class="{
                'badge-danger':
                  `${marker.stockType}` === '24H' ||
                  `${marker.stockType}` === '24 H',
                'badge-warning':
                  `${marker.stockType}` === 'DEMI JOURNEE' ||
                  `${marker.stockType}` === 'Jusqu\'à 20h' ||
                  `${marker.stockType}` === 'jusqu\'à 16H',
                'badge-success':
                  `${marker.stockType}` === 'VIDE',
                'badge-secondary':
                  `${marker.stockType}` === '-' ||
                  `${marker.stockType}` === 'NON COMMUNIQUE',
              }"
            >
              {{ marker.stockType }} </span
            ><br />
            <span style="font-weight: 550">Province : </span>
            {{ marker.province.name }}
            <!-- <div style="height: 90%; display: flex">
              <p style="font-weight: bold; font-size: 15px; grid-area: title">
                {{ marker.name }}
              </p>
            </div>
            <div>
              <table>
                <tr>
                  <td>
                    <p style="font-size: 13px; font-weight: bold">
                      {{ $t("tooltips.stockType.text") }} : &nbsp;
                    </p>
                  </td>
                  <td>
                    <p
                      class="badge font-size-12"
                      :class="{
                        'badge-danger':
                          `${marker.stockType}` === '24H' ||
                          `${marker.stockType}` === '24 H' ||
                          `${marker.stockType}` === '24 H ' ||
                          `${marker.stockType}` === '24H ',
                        'badge-warning':
                          `${marker.stockType}` === 'DEMI JOURNEE' ||
                          `${marker.stockType}` === 'jusqu\'à 20H' ||
                          `${marker.stockType}` === 'Jusqu\'à 20h',
                        'badge-success':
                          `${marker.stockType}` === 'VIDE' ||
                          `${marker.stockType}` === 'VIDE',
                        'badge-secondary':
                          `${marker.stockType}` === '-' ||
                          `${marker.stockType}` === 'NON COMMUNIQUE',
                      }"
                    >
                      {{ marker.stockType }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style="font-size: 13px; font-weight: bold">
                      Province : &nbsp;
                    </p>
                  </td>
                  <td>
                    <p style="font-size: 13px">{{ marker.province.name }}</p>
                  </td>
                </tr>
              </table>
            </div> -->
          </l-tooltip>
        </l-marker>
        <l-marker
          :zIndexOffset="100"
          v-if="markerStart.position"
          :draggable="false"
          :lat-lng="markerStart.position"
          :icon="icon"
        >
          <l-icon
            :icon-anchor="iconAnchor"
            :icon-size="iconSize"
            :tooltipAnchor="tooltipAnchor"
          >
            <img :src="imageStart" />
          </l-icon>
          <!-- <l-tooltip
            class="rounded"
            :options="{
              interactive: 'true',
              direction: 'top',
            }"
          >
          
          </l-tooltip> -->
        </l-marker>
        <l-polyline :lat-lngs="positionsData" color="#FF2424"></l-polyline>
      </l-map>
    </div>
  </div>
</template>
<style scoped>
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
}
</style>